var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-3" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm",
                attrs: { title: "점검설비 상세" },
              },
              [
                _c(
                  "template",
                  { slot: "card-button" },
                  [_c("q-btn-group", { attrs: { outline: "" } })],
                  1
                ),
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-text", {
                        attrs: {
                          editable: _vm.editInfo,
                          disabled: true,
                          label: "설비유형",
                          name: "equipmentTypeName",
                        },
                        model: {
                          value: _vm.preStartupEquipment.equipmentTypeName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.preStartupEquipment,
                              "equipmentTypeName",
                              $$v
                            )
                          },
                          expression: "preStartupEquipment.equipmentTypeName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-field", {
                        attrs: {
                          required: true,
                          editable: _vm.editInfo,
                          disabled: _vm.disabled,
                          type: "user",
                          label: "설비점검책임자",
                          name: "checkUserId",
                        },
                        model: {
                          value: _vm.preStartupEquipment.checkUserId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.preStartupEquipment,
                              "checkUserId",
                              $$v
                            )
                          },
                          expression: "preStartupEquipment.checkUserId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("c-multi-select", {
                        attrs: {
                          editable: _vm.editInfo,
                          disabled: _vm.disabled,
                          isObject: true,
                          disabledItems: _vm.disabledItems,
                          codeGroupCd: "PSR_CHECK_TYPE_CD",
                          itemText: "codeName",
                          itemValue: "code",
                          valueText: "psrCheckTypeName",
                          valueKey: "psrCheckTypeCd",
                          label: "점검유형",
                          name: "checkTypes",
                        },
                        on: { datachange: _vm.changeCheckType },
                        model: {
                          value: _vm.preStartupEquipment.checkTypes,
                          callback: function ($$v) {
                            _vm.$set(_vm.preStartupEquipment, "checkTypes", $$v)
                          },
                          expression: "preStartupEquipment.checkTypes",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-datepicker", {
                        attrs: {
                          editable: _vm.editInfo,
                          disabled: _vm.disabled,
                          label: "점검예정일",
                          name: "checkScheduleDate",
                        },
                        model: {
                          value: _vm.preStartupEquipment.checkScheduleDate,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.preStartupEquipment,
                              "checkScheduleDate",
                              $$v
                            )
                          },
                          expression: "preStartupEquipment.checkScheduleDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-datepicker", {
                        attrs: {
                          editable: _vm.editInfo,
                          disabled: _vm.disabled,
                          label: "점검일",
                          name: "checkDate",
                        },
                        model: {
                          value: _vm.preStartupEquipment.checkDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.preStartupEquipment, "checkDate", $$v)
                          },
                          expression: "preStartupEquipment.checkDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("c-textarea", {
                        attrs: {
                          editable: _vm.editInfo,
                          disabled: _vm.disabled,
                          rows: 5,
                          label: "점검결과종합",
                          name: "checkResultTotal",
                        },
                        model: {
                          value: _vm.preStartupEquipment.checkResultTotal,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.preStartupEquipment,
                              "checkResultTotal",
                              $$v
                            )
                          },
                          expression: "preStartupEquipment.checkResultTotal",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-8 col-lg-9" },
          [
            _c("c-table", {
              ref: "table",
              attrs: {
                title: "점검결과 목록",
                columns: _vm.grid.columns,
                gridHeight: _vm.grid.height,
                data: _vm.preStartupEquipment.checkItems,
                isTitle: true,
                merge: _vm.grid.merge,
                filtering: false,
                columnSetting: false,
                usePaging: true,
                editable: _vm.editInfo && !_vm.disabled,
                isTop: false,
              },
              scopedSlots: _vm._u([
                {
                  key: "customArea",
                  fn: function ({ props, col }) {
                    return [
                      col.name === "customCol"
                        ? [
                            _c(_vm.imprComponent, {
                              tag: "component",
                              attrs: {
                                col: col,
                                props: props,
                                inputEditable: _vm.editable && !_vm.disabled,
                                isImmShow: true,
                                requestContentsCols: _vm.requestContentsCols,
                                tableKey:
                                  "sopPrestartupEquipmentHasCheckItemId",
                                ibmTaskTypeCd: "ITT0000030",
                                ibmTaskUnderTypeCd: "ITTU000050",
                                acceptBtnShow: true,
                              },
                              on: { imprChange: _vm.imprChange },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "customArea",
                                    fn: function ({ props }) {
                                      return [
                                        _c("div", { staticClass: "col-6" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "mini-dailog-title",
                                            },
                                            [_vm._v("점검결과")]
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "col-12" },
                                          [
                                            _c("c-text", {
                                              attrs: {
                                                editable:
                                                  _vm.editInfo && !_vm.disabled,
                                                label: "",
                                                name: "checkResult",
                                              },
                                              model: {
                                                value: props.row.checkResult,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    props.row,
                                                    "checkResult",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "props.row.checkResult",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ]
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }